import { ICONS } from '@emobg/vue-base';

export const ICONS_MAPPER = (isReportsItem = false) => ({
  'icon-home': ICONS.home,
  'icon-user': ICONS.user,
  'icon-calendar': ICONS.calendar,
  'icon-wallet': ICONS.wallet,
  'icon-chart': isReportsItem ? ICONS.report : ICONS.analytics,
  'icon-settings': ICONS.settings,
  'icon-speedometer': ICONS.speedLimiter,
  'icon-map': ICONS.maps,
});
