var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MenuItemComponent w-100" },
    [
      _vm._l(_vm.items, function (element, index) {
        return [
          _c(
            "div",
            { key: index, staticClass: "pt-4" },
            [
              element.icon
                ? _c("ui-icon", {
                    attrs: {
                      icon: _vm.iconsMap[element.icon],
                      color: _vm.GRAYSCALE.white,
                      size: _vm.ICONS_SIZES.medium,
                    },
                  })
                : _vm._e(),
              _c("Transition", { attrs: { name: "page" } }, [
                _vm.isSidebarExpanded()
                  ? _c(
                      "span",
                      { staticClass: "ml-2" },
                      [
                        element.url === "#" && element.children.length
                          ? [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "cursor-pointer emobg-color-white",
                                  class: {
                                    expanded: _vm.isExpanded(element, index),
                                  },
                                  attrs: {
                                    "data-test-id": `menu-item-${_vm.kebabCase(
                                      element.label
                                    )}`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.setMenuItemOpen(_vm.itemId(index))
                                    },
                                  },
                                },
                                [
                                  _vm._v(_vm._s(element.label) + " "),
                                  _c("ui-icon", {
                                    staticClass: "arrow position-absolute",
                                    attrs: { icon: _vm.ICONS.arrowDown },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _c("span", { staticClass: "d-inline-block" }, [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.setMenuItemOpen(_vm.itemId(index))
                                    },
                                  },
                                },
                                [
                                  element.is_spa === false
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "emobg-color-white",
                                          attrs: {
                                            href: element.url,
                                            "data-test-id": `menu-item-${_vm.kebabCase(
                                              element.label
                                            )}`,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(element.label) + " "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "RouterLink",
                                        {
                                          staticClass: "emobg-color-white",
                                          attrs: {
                                            to: element.url,
                                            "data-test-id": `menu-item-${_vm.kebabCase(
                                              element.label
                                            )}`,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setSidebarExpanded(
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(element.label) + " "
                                          ),
                                        ]
                                      ),
                                ],
                                1
                              ),
                            ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _vm.isExpanded(element, index)
                ? _c("MenuItemComponent", {
                    staticClass: "ml-3 pl-3 animated fadeIn cursor-pointer",
                    attrs: {
                      items: element.children,
                      "parent-id": _vm.itemId(index),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }