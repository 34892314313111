<script>
import kebabCase from 'lodash/kebabCase';
import split from 'lodash/split';
import find from 'lodash/find';
import { mapActions, mapGetters, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { ICONS_MAPPER } from './icons.const';

export default {
  name: 'MenuItemComponent',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    parentId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.menuItems, [
      'sidebarState',
      'menuItemOpen',
    ]),
    iconsMap() {
      const isReportsItem = find(this.items, { label: 'Reports' });
      return ICONS_MAPPER(isReportsItem);
    },
  },
  methods: {
    kebabCase,
    ...mapGetters(DOMAINS_MODEL.app.menuItems, ['isSidebarExpanded']),
    itemId(index) {
      return (this.parentId) ? `${this.parentId}__${this._uid}-${index}` : `${this._uid}-${index}`;
    },
    isExpanded(element, index) {
      const expandedItems = split(this.menuItemOpen, '__');
      const targetExpanded = `${this._uid}-${index}`;

      return (element.children.length && expandedItems.includes(targetExpanded));
    },
    ...mapActions(DOMAINS_MODEL.app.menuItems, [
      'setSidebarExpanded',
      'setMenuItemOpen',
    ]),
  },
};
</script>

<template>
  <div class="MenuItemComponent w-100">
    <template v-for="(element, index) in items">
      <div
        :key="index"
        class="pt-4"
      >
        <ui-icon
          v-if="element.icon"
          :icon="iconsMap[element.icon]"
          :color="GRAYSCALE.white"
          :size="ICONS_SIZES.medium"
        />
        <Transition name="page">
          <span
            v-if="isSidebarExpanded()"
            class="ml-2"
          >
            <template v-if="element.url === '#' && element.children.length">
              <span
                class="cursor-pointer emobg-color-white"
                :class="{ 'expanded': isExpanded(element, index) }"
                :data-test-id="`menu-item-${kebabCase(element.label)}`"
                @click="setMenuItemOpen(itemId(index))"
              >{{ element.label }}
                <ui-icon
                  class="arrow position-absolute"
                  :icon="ICONS.arrowDown"
                />
              </span>
            </template>
            <span
              v-else
              class="d-inline-block"
            >
              <span @click="setMenuItemOpen(itemId(index))">
                <a
                  v-if="element.is_spa === false"
                  :href="element.url"
                  class="emobg-color-white"
                  :data-test-id="`menu-item-${kebabCase(element.label)}`"
                >
                  {{ element.label }}
                </a>
                <RouterLink
                  v-else
                  :to="element.url"
                  class="emobg-color-white"
                  :data-test-id="`menu-item-${kebabCase(element.label)}`"
                  @click="setSidebarExpanded(false)"
                >
                  {{ element.label }}
                </RouterLink>
              </span>
            </span>
          </span>
        </Transition>
        <MenuItemComponent
          v-if="isExpanded(element, index)"
          :items="element.children"
          :parent-id="itemId(index)"
          class="ml-3 pl-3 animated fadeIn cursor-pointer"
        />
      </div>
    </template>
  </div>
</template>
